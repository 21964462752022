import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import GetQuestionDetailsService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetQuestionDetailsService";
import SubmitAnswerServices from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/SubmitAnswerServices";
import GetProgressBarDataService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetProgressBarDataService";
import PriveTab from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/Prev_Ques_Arrow.svg";
import DownloadIcon from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/DownloadIcon.svg";
import AnsArrow from "../../../../../../../../designUtils/Storeoverview/BuildBrand/BrandQuestionair/AnsArrow.svg";
import backButton from "../../../../../../../../designUtils/Logo/BackButton.svg";
import ColorPalate from "./Components/ColorPalate";
import NewBrandProfiles from "../../../../../../../../designUtils/Logo/New_Brand_Profiles.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons/faWindowClose";
import { faCloudUploadAlt, faDownload,faFileDownload } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../../../../../../Components/Modals/CustomModal";
import ViewFileList from "./Components/ViewFileList";
import ReactTable from "react-table";
import { faEye,faEdit,faTrash } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as FileSaver from 'file-saver';
import notify from '../../../../../../../../../src/Notifications/ToastifyActions';
import uploadAssetsService from "../../../../../../../../Service/DashboardServices/AssetGalleryServices/uploadAssetsService";
import DownloadBrandDetailsService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/DownloadBrandDetailsService";
import New_LOGO from '../../../../../../../../../src/assets/utils/images/New_LOGO.png'
import GetBrandProfileListService from "../../../../../../../../Service/DashboardServices/QuestionnaireServices/GetBrandProfileListService";

function CreateBrandProfileMainPage(props) {
  const [categoryTab, setCategoryTab] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [flag, setFlag] = useState(0);
  const [questionId, setQuestionId] = useState();
  const [answerId, setAnswerId] = useState();
  const [answerText, setAnswerText] = useState();
  const [categoryID, setCategoryID] = useState();
  const [categoryTabsMappingId, setCategoryTabsMappingId] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentId, setCurrentId] = useState();
  const [currentQuestionId, setCurrentQuestionId] = useState();
  const [subCategoryCount, setSubCategoryCount] = useState([]);
  const [totalSubCategoryCount, setTotalSubCategoryCount] = useState();
  const [brand, setBrand] = useState();
  const [isPreviousFile, setIsPreviousFile] = useState(false);
  const [fileUrl, setFileUrl] = useState('')
  const [showFile, setShowFile] = useState(false)
  const [searchFileInput, setSearchFileInput] = useState("");
  const [questionnaireAnswersId, setQuestionnaireAnswersId] = useState(0)
  const [errorMassageOfFile, setErrorMassageOfFile] = useState()
  const [submitAnswerPopUp, setSubmitAnswerPopUp] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [submittedColors, setSubmittedColors] = useState('')
  const [showDownloadButton, setShowDownloadButton] = useState(false)
  const [isPreviewModeOn, setIsPreviewModeOn] = useState(false)
  const [inActiveTabCount, setInActiveTabCount] = useState([])
  const [isConfirmBoxOpen, setIsConfirmBoxOpen] = useState(false)
  const [isCheckBoxClick, setIsCheckBoxClick] = useState(false)
  const [isPreviouslyAnswered, setIsPreviouslyAnswered] = useState(false)
  const [firstCategoryTab,setFirstCategoryTab]=useState('')
  const [downloadAction,setDownloadAction]= useState(false)
  const [active,setActive]= useState(false)
  // ==================
  //  simple text and calender
console.log('filteredQuestions[currentQuestionIndex]')
console.log(filteredQuestions[currentQuestionIndex])
  const [textboxValue, setTextboxValue] = useState(
    filteredQuestions[currentQuestionIndex]
      ? filteredQuestions[currentQuestionIndex].answerObject[0]
        ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
        : ""
      : ""
  );

  // single choice
  const [answeredOptions, setAnsweredOptions] = useState(
    filteredQuestions[currentQuestionIndex]
      ? filteredQuestions[currentQuestionIndex].answerObject[0]
        ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
        : ""
      : ""
  );

  // multiple choice
  const [checkedOptions, setCheckedOptions] = useState(filteredQuestions[currentQuestionIndex]
    ? filteredQuestions[currentQuestionIndex].answerObject[0]
      ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
      : ""
    : "");
  const [checkedId, setCheckedId] = useState([]);
  const [tempraryCheckedOptions, setTempraryCheckedOptions] = useState([]);

  // file
  const [selectFile, setSelectFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  // ==================
  useEffect(() => {
    console.log('props.brandId')
    console.log(props.brand)
    let data = {
      category_id: props.categoryID?props.categoryID:0,
      brandId: brand ? brand.id : props.brand.id,
      asin: props.asin?props.asin:'',
    };
    console.log(data);
    GetQuestionDetailsService(props.token, data).then((response) => {
      if (response) {
        setFirstCategoryTab(response.categoryTabList[0].categories)
        setCategoryTab(response.categoryTabList);
        const inActiveTabCount = response.categoryTabList.filter(tab => tab.isActive==false);
        setInActiveTabCount(inActiveTabCount)
        setAllQuestions(response.questionDetailsList)
        setFilteredQuestions(response.questionDetailsList);
        setBrand(response.brandId)
        console.log(response);
        setBrand(response.brandId)
      }
    });
    GetProgressBarData();
  }, []);

  const GetProgressBarData = () => {
    let data = {
      brandId: brand ? brand.id : props.brand.id,
      asin:props.asin?props.asin:''
    }
    GetProgressBarDataService(props.token, data).then((response) => {
      if (response) {
        setSubCategoryCount(response.subCategoryCount);
        setTotalSubCategoryCount(response.totalSubCategoryCount);
        console.log(response);
      }
    });
  };

  const handlePrevClick = () => {
    setFlag(0);
    setSelectedFiles([]);
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    let brnd = brand ? brand.id : props.brand.id;
    if(questionId == 69)
    {
      const brandObj = brandProlife.find((item) => answerText == item.brandName)
      brnd = brandObj.id
    }
    let data = {
      brandId:brnd,
      asin:props.asin?props.asin:''
    }

    setErrorMassageOfFile(null);
    console.log(flag);

    if (flag === 0) {
      GetProgressBarDataService(props.token, data).then((response) => {
        if (response) {
          setSubCategoryCount(response.subCategoryCount);
          setTotalSubCategoryCount(response.totalSubCategoryCount);
          setTimeout(() => {
            setCurrentQuestionIndex((prevIndex) =>
              Math.min(prevIndex + 1, filteredQuestions.length - 1)
            );
          }, 300);
        }
      });

    }
  };

  const setSubmitQuestionAnswerData = (questionInfo, answer_id, answer_text, type) => {
    console.log("Question Info:", questionInfo);
    console.log("Answer ID:", answer_id);
    console.log("Answer Text:", answer_text);
    console.log("Type:", type);

    // Ensure answer_text is a valid string and trim it
    let trimmedText
    if(answer_id != 'Colors' && answer_id != 'Files')
    {
      trimmedText = answer_text?answer_text.trim():'' || "";
    }
    // Update flag based on whether answer_text is empty or not
    setFlag(trimmedText !== "" ? 1 : 0);

    // Set common state values
    setCategoryID(questionInfo.categories);
    setCategoryTabsMappingId(questionInfo.categoryTabsMapping);
    setQuestionId(questionInfo.id);
    setAnswerId(answer_id);
    setAnswerText(answer_text);

    // Handle specific question types
    switch (type) {
        case "ShortText":
        case "SimpleText":
            if (questionInfo.id === 69) {
                setBrand({ id: answer_text, brandName: "" });
            }
            break;

        case "Colors":
        case "Dropdown":
            break;

        case "SingleChoice":
            setAnswerText(`['${answer_text}']`); // Ensure SingleChoice answers are stored as arrays
            break;

        case "MultipleChoice":
            const isOptionChecked = tempraryCheckedOptions.includes(answer_text);
            const isIdChecked = checkedId.includes(answer_id);

            if (isOptionChecked) {
                const updatedOptions = tempraryCheckedOptions.filter(option => option !== answer_text);
                setCheckedOptions(updatedOptions);
                setTempraryCheckedOptions(updatedOptions);
                setAnswerText(updatedOptions);
            } else {
                const updatedOptions = [...tempraryCheckedOptions, answer_text];
                setCheckedOptions(updatedOptions);
                setTempraryCheckedOptions(updatedOptions);
                setAnswerText(updatedOptions);
            }

            if (isIdChecked) {
                const updatedId = checkedId.filter(option => option !== answer_id);
                setCheckedId(updatedId);
                setAnswerId(updatedId.join(","));
            } else {
                const updatedId = [...checkedId, answer_id];
                setCheckedId(updatedId);
                setAnswerId(updatedId.join(","));
            }
            break;

        case "Files":
            // File handling logic (if needed)
            break;

        default:
            console.warn("Unknown question type:", type);
    }
};
  const handleSubmitAnswer = () => {
    if (flag === 0 ) {
      return true;
    }
    if (questionId && flag === 1) {
      // text
      if (answerId === 0 && answerText) {
        let brnd = brand ? brand.id : props.brand.id;
        if(questionId == 69)
        {
          const brandObj = brandProlife.find((item) => answerText == item.brandName)
          brnd = brandObj.id
        }
        let data = {
          questionId: questionId,
          answerId: answerId,
          brandId: brnd,
          asin: props.asin?props.asin:'',
          integerId: props.storeOverview.integerID,
          answerText: answerText,
          categoryID: categoryID,
          categoryTabsMappingId: categoryTabsMappingId,
          questionnaireAnswersId: questionnaireAnswersId
        };
        SubmitAnswerServices(props.token, data).then((res) => {
          if (res) {
            setFlag(0);
            setCategoryID(0);
            setCategoryTabsMappingId(0);
            setQuestionId(0);
            setAnswerId(0);
            setSubmittedColors('')
            setAnswerText(0);
            console.log(res);
            setFilteredQuestions(res.questionDetailsList);
            setAllQuestions(res.questionDetailsList)
            setBrand(res.brandId)
            setSubCategoryCount(res.progressBarData.subCategoryCount);
            setTotalSubCategoryCount(res.progressBarData.totalSubCategoryCount);
            setQuestionnaireAnswersId(0)
            setTimeout(() => {
              setCurrentQuestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredQuestions.length - 1)
              );
            }, 300);
           setActive(true)

          }
        });
      }
      if (answerId !== 0 && answerText && answerId !== "Files") {
        const answerIdString = answerId //Array.isArray(answerId) ? answerId.join(', ') :answerId;
        const answerTextString = answerText// Array.isArray(answerText) ? answerText.join(', ') :answerText;
        let data = {
          questionId: questionId,
          answerId: answerIdString,
          brandId: brand ? brand.id : props.brand.id,
          asin: props.asin?props.asin:'',
          answerText: answerTextString,
          integerId: props.storeOverview.integerID,
          categoryID: categoryID,
          categoryTabsMappingId: categoryTabsMappingId,
          questionnaireAnswersId: questionnaireAnswersId
        };
        SubmitAnswerServices(props.token, data).then((res) => {
          if (res) {
            setFlag(0);
            setCategoryID(0);
            setCategoryTabsMappingId(0);
            setQuestionId(0);
            setAnswerId(0);
            setAnswerText(0);
            setSubmittedColors('')
            // GetProgressBarData();
            setFilteredQuestions(res.questionDetailsList);
            setAllQuestions(res.questionDetailsList)
            setBrand(res.brandId)
            setCheckedOptions('')
            setTempraryCheckedOptions([])
            setCheckedId('')
            setSubCategoryCount(res.progressBarData.subCategoryCount);
            setTotalSubCategoryCount(res.progressBarData.totalSubCategoryCount);
            setQuestionnaireAnswersId(0)
            setTimeout(() => {
              setCurrentQuestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredQuestions.length - 1)
              );
            }, 300);
          }
        });
      }
      // file
      if (answerId == "Files") {

        if (selectedFiles.length == 0) {
          setFlag(0);
          setCategoryID(0);
          setCategoryTabsMappingId(0);
          setAnswerId(0);
          setAnswerText(0);
          setSelectedFiles([]);
          return true
        }
        const data = new FormData();
        data.append("questionId", questionId);
        data.append("answerId", 0);
        data.append("brandId", brand ? brand.id : props.brand.id);
        data.append("asin", props.asin?props.asin:'');
        data.append("answerText", "Files");
        data.append("categoryID", categoryID);
        data.append("integerId", props.storeOverview.integerID);
        data.append("categoryTabsMappingId", categoryTabsMappingId);
        data.append("questionnaireAnswersId", questionnaireAnswersId);

        for (let i = 0; i < selectedFiles.length; i++) {
          data.append("attachmentFiles", selectedFiles[i]);
        }

        SubmitAnswerServices(props.token, data).then((res) => {
          if (res) {
            setFlag(0);
            setCategoryID(0);
            setCategoryTabsMappingId(0);
            setAnswerId(0);
            setAnswerText(0);
            setSubmittedColors('')
            setSelectedFiles([]);
            // GetProgressBarData();
            setFilteredQuestions(res.questionDetailsList);
            setAllQuestions(res.questionDetailsList)
            setBrand(res.brandId)
            setSubCategoryCount(res.progressBarData.subCategoryCount);
            setTotalSubCategoryCount(res.progressBarData.totalSubCategoryCount);
            setQuestionnaireAnswersId(0)
            setTimeout(() => {
              setCurrentQuestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, filteredQuestions.length - 1)
              );
            }, 300);
          }
        });
        const newData = new FormData();
        newData.append("subCategoryId", props.categoryID == 1 ? 5: categoryTabsMappingId);
        newData.append("integerId", props.storeOverview.integerID);
        newData.append("assetCategory", props.categoryID);
        newData.append("assetIdentifier", brand ? brand.brandName : props.brand.brandName);
        newData.append("notes", '');

        if (selectedFiles.length > 0) {
            // newData.append("fileType", 'Image');
            for (let i = 0; i < selectedFiles.length; i++) {
                newData.append("attachmentFiles", selectedFiles[i]);
            }
            newData.append("attachmentFiles1", 0);
            newData.append("handleLinkFile",0);
        }

        uploadAssetsService(props.token,newData).then((res)=>{
          if(res){
            console.log(res)
          }
        })
      }
    }
    else{
      setFlag(0)
      return true;
    }
  };
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    if (input === '') {
      setFilteredQuestions(allQuestions);
      setCurrentQuestionIndex(0); 
    } else {
      filterQuestions(input, 0);
    }
  };
  const filterQuestions = (input, flag) => {
    const stringQuery = input.toString();
    if (flag === 1) {
      const filtered = filteredQuestions.filter((question) =>
        question.categoryTabsMapping
          .toString()
          .toLowerCase()
          .includes(stringQuery.toLowerCase())
      );
      // console.log(filtered);
      if (filtered.length > 0) {
        setCurrentQuestionIndex((prevIndex) =>
          Math.min(filtered[0].sequence, filteredQuestions.length)
        );
      } else {
        return true;
      }
    } else {
      console.log('Else');
      if (input !== '') {
        console.log('input =!');
        console.log(input);
        const filtered = filteredQuestions.filter((question) =>
          question.questionText.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredQuestions(filtered);
        setCurrentQuestionIndex((prevIndex) =>
          Math.min(prevIndex, filtered.length - 1)
        );
      }
      console.log(filteredQuestions);

    }
  };

  const setProgressCount = (tabId, questionId) => {
    if (currentQuestionId != questionId) {
      const relevantSubType = subCategoryCount.find(
        (subType) => subType.categoryTabsMapping_id == tabId
      );
      if (relevantSubType) {
        // console.log('relevantSubType id' + relevantSubType)
        let count = 100 / relevantSubType.questionCount;
        setProgress(relevantSubType.attemptedQuestionCount * count);
        setCurrentId(tabId);
        setCurrentQuestionId(questionId);
      }
    }
    // console.log('after id')
  };
  const defaultAnswers = (tabId, questionId) => {
    // default saving data 
    if (currentQuestionId != questionId) {
      setTextboxValue(filteredQuestions[currentQuestionIndex]
        ? filteredQuestions[currentQuestionIndex].answerObject[0]
          ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
          : ""
        : "")
      setAnsweredOptions(filteredQuestions[currentQuestionIndex]
        ? filteredQuestions[currentQuestionIndex].answerObject[0]
          ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
          : ""
        : "")
      setCheckedOptions(filteredQuestions[currentQuestionIndex]
        ? filteredQuestions[currentQuestionIndex].answerObject[0]
          ? filteredQuestions[currentQuestionIndex].answerObject[0].answerText
          : ""
        : "")
    }
  }
  const setCategoryTabID = (input) => {
    
    const shouldDisable = filteredQuestions[currentQuestionIndex] &&
    filteredQuestions[currentQuestionIndex].id == 1
    ?filteredQuestions[currentQuestionIndex].answerObject==['']
      ? active:textboxValue
        ? false
        : true
      : false

    if (input > 1 && shouldDisable ) {
      if (filteredQuestions[currentQuestionIndex] && filteredQuestions[currentQuestionIndex].id > 1) {
        filterQuestions(input, 1);
      }
      else {
        return true
      }

    }
    filterQuestions(input, 1);
  };


  // calender

  const handleDateChange = (e) => {
    const value = e.target.value;
    setTextboxValue(value);
    setSubmitQuestionAnswerData(
      filteredQuestions[currentQuestionIndex],
      0,
      value,
      "SimpleText"
    );
  };
  // file
  const allowedTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const allowedExtensions = ['png', 'jpg', 'jpeg', 'svg'];
  
    let filteredFiles = [];
    let invalidFiles = [];
  
    files.forEach((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(extension)) {
        filteredFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    });
    if (filteredQuestions[currentQuestionIndex].id === 46) {
      if (invalidFiles.length > 0) {
        setErrorMassageOfFile(
          `Invalid file(s) detected: ${invalidFiles.join(', ')}. Only PNG, JPG, JPEG, and SVG files are allowed.`
        );
      } else {
        setErrorMassageOfFile('');
      }
      setSelectedFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    } else {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    }
  };
  
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
    setErrorMassageOfFile(null);
    if (updatedFiles.length == 0) {
      setFlag(0)
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
  
    const droppedFiles = Array.from(e.dataTransfer.files);
    const allowedExtensions = ['png', 'jpg', 'jpeg', 'svg'];
  
    let validFiles = [];
    let invalidFiles = [];
  
    droppedFiles.forEach((file) => {
      const extension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(extension)) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    });
  
    if (filteredQuestions[currentQuestionIndex].id === 46) {
      if (invalidFiles.length > 0) {
        setErrorMassageOfFile(
          `Invalid file(s) detected: ${invalidFiles.join(', ')}. Only PNG, JPG, JPEG, and SVG files are allowed.`
        );
        return;
      } else {
        setErrorMassageOfFile('');
      }
    }
  
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    setSubmitQuestionAnswerData(filteredQuestions[currentQuestionIndex], "Files", e, "Files");
  };
  
  // const deleteFile = ()=>{
  //   alert('Development for this functionality is in progress..');
  // }
  // const downloadFile = ()=>{
  //   alert('Development for this functionality is in progress..');
  // }
  const openFile = (url) => {
    const separatedPath = url.split("/static/");
    const ext = separatedPath[1].split(".")[1];
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx"|| ext == "doc" || ext == 'xlsm') {
      setFileUrl(url)
      setShowFile(true)
      setTimeout(() => {
        setShowFile(false);
      }, 500);
    } else {
      setFileUrl(url)
      setShowFile(true)
    }

  }
  const columns = [
    {
      Header: "Date",
      width: 150,
      accessor: "createdAt",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p className="m-0">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Assets",
      width: 350,
      accessor: "actualFileName",
      Cell: (cellInfo) => (
        <div className="d-flex  w-100">
          <p className="m-0 comment-paragraph">{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "View",
      width: 111,
      accessor: "attachmentFile",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100" style={{ cursor: "pointer" }}>
          <p className="m-0 text-center" >
          <FontAwesomeIcon icon={faEye} onClick={() => openFile(cellInfo.value)} />
            {/* <FontAwesomeIcon icon={faTrash} onClick={() => deleteFile(cellInfo.value)} className='mr-3'/>
            <FontAwesomeIcon icon={faDownload} onClick={() => downloadFile(cellInfo.value)} className='mr-3'  /> */}
          </p>
        </div>
      ),
    }
  ]

  const filterAssets = filteredQuestions[currentQuestionIndex] ?
    filteredQuestions[currentQuestionIndex].fileList ?
      filteredQuestions[currentQuestionIndex].fileList.fileList.length > 0 ?
        filteredQuestions[currentQuestionIndex].fileList.fileList.filter((row) => {
          const assetsName = row.actualFileName.toLowerCase();
          if (row.questionnaireAnswers != questionnaireAnswersId) {
            setQuestionnaireAnswersId(row.questionnaireAnswers)
          }
          return assetsName.includes(searchFileInput.toLowerCase());
        })
        : []
      : []
    : [];
  if (filterAssets) {
    if (filterAssets.length == 0 && questionnaireAnswersId != filterAssets.length) {
      setQuestionnaireAnswersId(0)
      // console.log(filterAssets.length)
    }
  }
  const handleSearchChange = (e) => {
    setSearchFileInput(e.target.value);
  };
//   const handleDownload = () => {
    // const downloadData = {
    //   categoryTab,
    //   subCategoryCount,
    //   filteredQuestions
    // };
// console.log(downloadData)
//     const json = JSON.stringify(downloadData, null, 2);

//     const blob = new Blob([json], { type: 'application/json' });

//     const url = URL.createObjectURL(blob);

//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'downloaded_data.json';

//     document.body.appendChild(a);
//     a.click();

//     document.body.removeChild(a);
//     URL.revokeObjectURL(url);
//   };



const handleDownload = () => {
  setDownloadAction(true)
  let data = {
    brandId : brand ? brand.id :props.brand.id,
    categoryID:props.categoryID,
    asin: props.asin?props.asin:'',
    brandName:brand ? brand.brandName : props.brand.brandName,
  };
  DownloadBrandDetailsService (props.token, data).then((res)=>{
    if(res){
      console.log(res.questionDetailsList)
      exportToPDFManagedProducts(res.questionDetailsList1,res.questionDetailsList2, props.brand.brandName)
      // exportToCSVManagedProducts(res.questionDetailsList, props.brand.brandName)
      setDownloadAction(false)
    }
  })
  
}

const exportToPDFManagedProducts = (pdfData, pdfData2, fileName) => {
  if (!pdfData || pdfData.length === 0) {
    console.error('PDF data is empty or not available.');
    return;
  }

  let includeSecondTable = true;
  if (!pdfData2 || pdfData2.length === 0) {
    includeSecondTable = false;
  }

  const doc = new jsPDF();

  doc.setProperties({
    title: fileName || 'Downloaded Data',
  });

  // Set font size for titles and general text
  doc.setFontSize(12);

  // Add title with dynamic line wrapping
  const addTitle = () => {
    const lineSpacing = 8; // Space between lines
    const marginX = 10; // Left margin
    let startY = 20; // Initial Y position
    const pageWidth = doc.internal.pageSize.getWidth();
    const maxWidth = pageWidth - marginX * 2; // Allow margin on both sides

    if (firstCategoryTab === 2) {
      // Add multi-line titles if necessary
      doc.setFont('helvetica', 'bold'); // Bold for "Store Name"
      doc.text('Store Name: ', marginX, startY);

      doc.setFont('helvetica', 'normal'); // Normal for the actual name content
      doc.text((props.storeOverview.name || 'N/A'), marginX + 30, startY); // Adjust the X-coordinate for alignment
      startY += lineSpacing;

      // Write "ASIN" (bold) and its content (normal)
      doc.setFont('helvetica', 'bold'); // Bold for "ASIN"
      doc.text('ASIN: ', marginX, startY);

      doc.setFont('helvetica', 'normal'); // Normal for the actual ASIN content
      doc.text((props.asin || 'N/A'), marginX + 30, startY); // Adjust the X-coordinate for alignment
      startY += lineSpacing;

      // Write "Title" (bold) and its content (normal)
      doc.setFont('helvetica', 'bold'); // Bold for "Title"
      doc.text('Title: ', marginX, startY);

      doc.setFont('helvetica', 'normal'); // Normal for the actual title content
      const titleContent = doc.splitTextToSize(props.titleProduct || 'N/A', maxWidth - 30); // Wrap long text
      doc.text(titleContent, marginX + 30, startY);
      startY += titleContent.length * lineSpacing; // Adjust for multi-line title
    } else {
      const longTitle = doc.splitTextToSize(
        (props.storeOverview.name || 'N/A') +
          ' : Brand Interview Questionnaire',
        maxWidth
      );
      doc.text(longTitle, marginX, startY);
      startY += longTitle.length * lineSpacing; // Adjust position for the next section
    }

    return startY-7; // Return the position where the next content starts
  };

  // Add logo to the page
  const addLogo = () => {
    const pageWidth = doc.internal.pageSize.getWidth();
    const logoWidth = 26; // Set desired width for logo
    const logoHeight = 8; // Set desired height for logo
    const logoXPosition = pageWidth - logoWidth - 10; // Align the logo to the right
    doc.addImage(New_LOGO, 'PNG', logoXPosition, 5, logoWidth, logoHeight);
  };

  // Calculate column widths and styles
  const pageWidth = doc.internal.pageSize.getWidth();
  const tableStartX = 10; // Table left margin
  const tableWidth = pageWidth - 2 * tableStartX;
  const columnWidth = [
    0.10 * tableWidth,
    0.15 * tableWidth,
    0.40 * tableWidth,
    0.35 * tableWidth,
  ];
  const columnStyles = {
    0: { cellWidth: columnWidth[0] }, // First column
    1: { cellWidth: columnWidth[1] }, // Second column
    2: { cellWidth: columnWidth[2] }, // Third column
    3: { cellWidth: columnWidth[3] }, // Fourth column
  };

  // Add title and get starting position for table
  const startY = addTitle();
  addLogo();

  // Add the first table
  const columns = Object.keys(pdfData[0]);
  const rows = pdfData.map((obj) => Object.values(obj));

  doc.autoTable({
    head: [columns],
    body: rows,
    startY: startY, // Start after title
    margin: { left: tableStartX },
    styles: {
      overflow: 'linebreak',
      fontSize: 10,
      cellPadding: 2,
    },
    columnStyles,
    didDrawPage: () => {
      addLogo(); // Add logo to every page
    },
  });

  // Add second table if data is available
  if (includeSecondTable) {
    doc.addPage();
    doc.text('Previously Answered Questions', 10, 10);

    const columns2 = Object.keys(pdfData2[0]);
    const rows2 = pdfData2.map((obj) => Object.values(obj));
    doc.autoTable({
      head: [columns2],
      body: rows2,
      startY: 20, // Start a bit lower to add space
      margin: { left: tableStartX },
      styles: {
        overflow: 'linebreak',
        fontSize: 10,
        cellPadding: 2,
      },
      columnStyles,
      didDrawPage: () => {
        addLogo(); // Add logo to every page
      },
    });
  }

  // Save the PDF with a proper filename
  if (firstCategoryTab === 2) {
    doc.save(
      (props.storeOverview.name || 'Downloaded_Data') +
        '_Product_Information_Questionnaire.pdf'
    );
  } else {
    doc.save(
      (props.storeOverview.name || 'Downloaded_Data') +
        '_BrandProfile_Questionnaire.pdf'
    );
  }
};




const exportToCSVManagedProducts = (csvData, fileName) => {
  if (!csvData) {
      notify.NotifySuccess('Task data is not available for this store.')
      return true
  }
  // let sheetName = fileName + '_Workflow'
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  console.log(csvData)
  console.log(fileName)
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'Brand Interview' : ws }, SheetNames: ['Brand Interview'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  // FileSaver.saveAs(data, fileName + '_BrandDetails_' + new Date() + fileExtension);
  FileSaver.saveAs(data, props.storeOverview.name+'_BrandProfile_Questionnaire'+new Date()+ fileExtension)
};



  const previewAnswers = () => {

    if (isChecked) {
      setIsConfirmBoxOpen(false);
      setIsPreviewModeOn(false)
      setIsChecked(prevState => !prevState);
    }
    else {
      if (isCheckBoxClick) {
        setIsConfirmBoxOpen(false);
        setIsPreviewModeOn(true)
        setIsChecked(prevState => !prevState);
      }
      else {
        setIsPreviewModeOn(true); 
        setIsChecked(prevState => !prevState);
      }
    }
  }
  const isLink = (text) => {
    return text.startsWith('http://') || text.startsWith('https://');
  };
  const [brandProlife, setBrandProfile] = useState([]);
  useEffect(() => {
    let data = {
        integerId:props.storeOverview.integerID
    }
    GetBrandProfileListService(props.token,data).then((response) => {
        if (response) {
          console.log(response);
            setBrandProfile(response.brandList);
        }
    });
}, []);
const isRowDisabled =
firstCategoryTab === 2
  ? filteredQuestions[currentQuestionIndex]
    ? filteredQuestions[currentQuestionIndex].id === 69 &&
      (!filteredQuestions[currentQuestionIndex].answerObject ||
        !filteredQuestions[currentQuestionIndex].answerObject.length ||
        !filteredQuestions[currentQuestionIndex].answerObject[0].answerText)
    : false
  : false;

const setBrandProfile1 = () =>{
  setBrand(brandProlife.find((item) => answerText == item.brandName));
}

  return (
    <div className={`${props.categoryID ==1?'container-fluid':""}`} fluid  id="questionnaire">
      <Row>
        <Col md={12}>
          <Card className="mb-3 card-of-problemarea">
            <Col md={12} className="pl-0">
            <Row>
              <Col md={7}>
              <h5 className="d-flex">
                  {props.categoryID == 1 ? (
                    <>
                      <button
                        onClick={() => {
                          props.closeQuestionnaire();
                        }}
                        className="hover-btn-link back-btn"
                      >
                        <img src={backButton} alt="not found" />
                      </button>
                      <div className="ml-2">
                        <img src={NewBrandProfiles} alt="not found" /> Brand Name :{" "}
                        {props.brand.id !== "New" ? (
                          <span>
                            <b>{props.brand.brandName}</b>
                          </span>
                        ) : (
                          <span>
                            <b>{brand ? brand.brandName : ""}</b>
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </h5>
              </Col>
              <Col md={5} className='d-flex'>
              <label className="toggle-switch ml-3 mt-1">
                    <input
                      disabled={
                        firstCategoryTab !== 2 &&
                        filteredQuestions[currentQuestionIndex] &&
                        filteredQuestions[currentQuestionIndex].id == 1
                        ?filteredQuestions[currentQuestionIndex].answerObject==['']
                        ? active:textboxValue
                            ? false
                            : true
                          : false
                      }
                      type="checkbox"
                      checked={isChecked}
                      // disabled={!isRowDisabled?true:false}
                      onChange={() => {
                        if(!isRowDisabled){
                          previewAnswers();
                          
                        }
                       else{
                        console.log('dont opne')
                       }
                      }}
                    />
                    <span className="dot"></span>
                  </label>
             
                  <span className="custom-label ml-1 mt-1 mr-2"> Preview Mode</span>
                  {(isPreviewModeOn === true)  ? (
                    <>
                     <button
                      // className="ml-5 mt-1 info-button-background-none"
                      title="Download Brand Interview"
                      className={`ml-5 mt-1 info-button-background-none ${downloadAction?'button-img-background-download-inprogress':''}`}
                      onClick={() => handleDownload()}
                      disabled={brand ? brand.brandName=='New'?true:false:(props.brand.brandName == 'New'? true:false)}
                    >
                      <FontAwesomeIcon icon={faDownload} style={{ fontSize: "20px" }} />
                    </button>
                    {downloadAction?<span className="mt-3">Downloading</span>:''}
                    </>
                   
                  ) : (
                    ""
                  )}
                  {isPreviewModeOn === false ? (
                    <div className="d-flex ml-3">
                      <Input
                        type="text"
                        className="form-control w-100"
                        placeholder="Search Questions"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        disabled={
                          filteredQuestions[currentQuestionIndex] &&
                          filteredQuestions[currentQuestionIndex].id == 1
                          ?filteredQuestions[currentQuestionIndex].answerObject==['']
                            ? active:textboxValue
                              ? false
                              : true
                            : false
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
              </Col>
            
            </Row>
            </Col>
            <Col md={12} className="p-2">
              <div className="d-flex">
                {categoryTab
                  ? categoryTab.map((cat_tab) => 
                  (<>{cat_tab.isActive == true?
                    
                    <Row
                          onClick={() => {
                            if (firstCategoryTab === 2 && !isRowDisabled) {
                              setCategoryTabID(cat_tab.id);
                              setIsPreviouslyAnswered(false);
                            }
                            else if(firstCategoryTab === 1){
                              setCategoryTabID(cat_tab.id);
                              setIsPreviouslyAnswered(false);
                            }
                            else{
                              console.log('dont ask')
                            }
                          }}
                          style={{cursor: firstCategoryTab === 2 && isRowDisabled ? "not-allowed" : "pointer" }}
                          className={
                            cat_tab.id == currentId && isPreviouslyAnswered == false
                              ? "selected-tab m-0"
                              : ""
                          }
                        >
                      <Col md={12} className="text-center">
                        <span key={cat_tab.id}>
                          <b>{cat_tab.tab}</b>
                        </span>
                      </Col>
                      <Col md={12} className="text-center">
                        {subCategoryCount
                          ? subCategoryCount.map((count) => (
                            <span>
                              {cat_tab.id === count.categoryTabsMapping_id
                                ? count.attemptedQuestionCount +
                                " of " +
                                count.questionCount +
                                " Completed"
                                : ""}
                            </span>
                          ))
                          : ""}
                      </Col>
                    </Row>:''}</>)
                  )
                  : ""}
                  {isPreviewModeOn && inActiveTabCount.length > 0 ? <Row
                      onClick={() => setIsPreviouslyAnswered(true)}
                      style={{ cursor: "pointer" }}
                      className={isPreviouslyAnswered? "selected-tab m-0" : ""}
                    >
                      <Col md={12} className="text-center">
                        <span key={isPreviouslyAnswered} className="ml-3">
                          <b>Previously Answered</b>
                        </span>
                      </Col>
                      {/* <Col md={12} className="text-center">
                        {subCategoryCount
                          ? subCategoryCount.map((count) => (
                            <span>
                              {cat_tab.id === count.categoryTabsMapping_id
                                ? count.attemptedQuestionCount +
                                " of " +
                                count.questionCount +
                                " Completed"
                                : ""}
                            </span>
                          ))
                          : ""}
                      </Col> */}
                    </Row> : ''}
              </div>
            </Col>
          </Card>
          <Card className={`card-min-height card-of-problemarea ${isPreviewModeOn ? 'd-none' : ''}`}>
            <Row>
              <Col md={12}>
                <div className="brand-progressBar-outer">
                  <div
                    className={
                      progress == 100
                        ? "progress-bar-curve brand-progressBar"
                        : "brand-progressBar"
                    }
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <div className="d-flex ml-1 mt-2">
                  {currentQuestionIndex !== 0 ? (
                    <img
                      src={PriveTab}
                      alt="Previous"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => handlePrevClick()}
                    />
                  ) : (
                    ""
                  )}
                  <span className="ml-3 mt-1">
                    {Math.floor(progress)}% Completed |{" "}
                    {categoryTab
                      ? categoryTab.map((cat_tab) =>
                        cat_tab.id == currentId ? (
                          <span key={cat_tab.id}> {cat_tab.tab}</span>
                        ) : (
                          ""
                        )
                      )
                      : ""}{" "}
                  </span>
                </div>
              </Col>
              {filteredQuestions[currentQuestionIndex] && (
                <>
                  {setProgressCount(
                    filteredQuestions[currentQuestionIndex].categoryTabsMapping,
                    filteredQuestions[currentQuestionIndex].id
                  )}
                  {defaultAnswers(
                    filteredQuestions[currentQuestionIndex].categoryTabsMapping,
                    filteredQuestions[currentQuestionIndex].id
                  )}
                  <Col
                    md={12}
                    className="pl-5 mt-4"
                    style={{ fontWeight: 500 }}
                  >
                    <h6>
                      {" "}
                      <b>
                        {" "}
                        {filteredQuestions[currentQuestionIndex].questionText} {filteredQuestions[currentQuestionIndex].id == 1 || filteredQuestions[currentQuestionIndex].id == 69 ? <span style={{ color: 'red' }}>*</span> : ''}
                      </b>
                    </h6>
                  </Col>
                  <Col md={12} className="pl-5">
                    {filteredQuestions[currentQuestionIndex].id == 69 ? (
                    <FormGroup>
                      <select
                         id="Dropdown"
                         name="Dropdown"
                         className="form-control w-50 mb-3"
                         value={textboxValue?textboxValue:brand?brand.id:""}
                         onChange={(e) =>
                           {setSubmitQuestionAnswerData(
                             filteredQuestions[currentQuestionIndex],
                             0,
                             e.target.value,
                             "SimpleText"
                           );
                           setTextboxValue(e.target.value)
                           setBrandProfile1()
                          }
                         }
                      >
                        <option value="">-- Select Brand --</option>
                        {brandProlife.map((item)=>(
                          <option value={item.brandName} >{item.brandName}</option>
                        ))}
                      </select>
                    </FormGroup>)
                   
                  :filteredQuestions[currentQuestionIndex].questionType ===
                      1 ? (
                      <FormGroup>
                        <textarea
                          id={`id${filteredQuestions[currentQuestionIndex].id}`}
                          name={`name${filteredQuestions[currentQuestionIndex].id}`}
                          rows={4}
                          className="w-50 mb-3"
                          value={textboxValue ? textboxValue : ""}
                          onChange={(e) => {
                            setTextboxValue(e.target.value);
                            setSubmitQuestionAnswerData(
                              filteredQuestions[currentQuestionIndex],
                              0,
                              e.target.value,
                              "SimpleText"
                            );
                          }}
                          required

                        ></textarea>
                      </FormGroup>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      2 ? (
                      <select
                        id="Dropdown"
                        name="Dropdown"
                        className="form-control w-50 mb-3"
                        onChange={(e) =>
                          setSubmitQuestionAnswerData(
                            filteredQuestions[currentQuestionIndex],
                            e.target.value,
                            e.target.name,
                            "Dropdown"
                          )
                        }
                      >
                        <option value="">--Select Answer --</option>
                        {filteredQuestions[currentQuestionIndex]
                          .answerOptionsForQ
                          ? filteredQuestions[
                            currentQuestionIndex
                          ].answerOptionsForQ.map((type) => (
                            <option value={type.id}>
                              {type.answerOptionText}
                            </option>
                          ))
                          : ""}
                      </select>
                    ) : filteredQuestions[currentQuestionIndex].questionType === 3 ? (
                      <>
                        <div className="mb-5">
                          <span className="">
                            {filteredQuestions[currentQuestionIndex]
                              ? filteredQuestions[
                                currentQuestionIndex
                              ].answerOptionsForQ.map((option) => (
                                <>
                                  <p className="ml-4">
                                    <Input
                                      type="radio"
                                      name="answerOption"
                                      value={option.answerOptionText}
                                      checked={answeredOptions.includes(option.answerOptionText)}
                                      onChange={(e) => {
                                        setAnsweredOptions(e.target.value);
                                        setSubmitQuestionAnswerData(
                                          filteredQuestions[
                                          currentQuestionIndex
                                          ],
                                          option.id,
                                          e.target.value,
                                          "SingleChoice"
                                        );
                                      }}
                                    />
                                    &nbsp;
                                    {option.answerOptionText}
                                  </p>
                                </>
                              ))
                              : ""}
                          </span>
                        </div>
                      </>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      4 ? (
                      <>
                        <FormGroup check className="mb-5">
                          {filteredQuestions[currentQuestionIndex]
                            ? filteredQuestions[
                              currentQuestionIndex
                            ].answerOptionsForQ.map((option) => (
                              <>
                                <p>
                                  <Input
                                    type="checkbox"
                                    name={"answerOption" + option.id}
                                    value={option.answerOptionText}
                                    checked={checkedOptions ? checkedOptions.toString().toLowerCase().includes(option.answerOptionText.toString().toLowerCase()) : ""}
                                    // checked={
                                    //   checkedOptions
                                    //     ? checkedOptions.some(optionText => optionText === option.answerOptionText)
                                    //     : ""
                                    // }                                    
                                    onChange={(e) => {
                                      setSubmitQuestionAnswerData(
                                        filteredQuestions[
                                        currentQuestionIndex
                                        ],
                                        option.id,
                                        e.target.value,
                                        "MultipleChoice"
                                      );
                                      // setAnsweredOptionsForCheckbox(option.id,option.answerOptionText,e);
                                    }}
                                  />
                                  &nbsp;
                                  {option.answerOptionText}
                                </p>
                              </>
                            ))
                            : ""}
                        </FormGroup>
                      </>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      5 ? (
                      <Col md={6} className="p-0">
                        <FormGroup>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </div>
                            </InputGroupAddon>
                            <Input
                              type="date"
                              className="form-control"
                              value={textboxValue}
                              onChange={handleDateChange}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      6 ? (
                      <Row className="mb-5 mr-0">
                        <Col
                          md={4}
                          className="p-4"
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragEnter={handleDragEnter}
                          onDragLeave={handleDragLeave}
                        >
                          <label
                            htmlFor="fileInput"
                            className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""
                              }`}

                          >
                            <h5>
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                style={{ fontSize: "30px" }}
                              />
                            </h5>
                            <h5>Drag &amp; Drop Files </h5>
                            <h5>OR</h5>
                            <input
                              type="file"
                              id="fileInput"
                              name="ticketAttachment"
                              className="custom-brand-file"
                              onChange={(e) => {
                                handleFileChange(e);
                                setSubmitQuestionAnswerData(
                                  filteredQuestions[currentQuestionIndex],
                                  "Files",
                                  e,
                                  "Files"
                                );
                              }}
                              style={{ display: "none" }}
                            />
                            <Button
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                              style={{ background: "#B2A1FF" }}
                            >
                              Browse Files
                            </Button>
                          </label>
                          {/* <Link onClick={() => setIsPreviousFile(true)}>
                            View Previously Uploaded Files
                          </Link> */}
                          {<p style={{ color: 'red' }}>{errorMassageOfFile}</p>}
                          <p style={{ fontSize: "13px" }}>
                            Attached File List
                          </p>
                          <ul style={{ color: "green", fontSize: "13px" }}>
                            {selectedFiles
                              ? selectedFiles.map((file, index) => (
                                <li key={index}>
                                  {file.name}
                                  <button
                                    className="btn"
                                    onClick={() => handleRemoveFile(file)}
                                  >
                                    <FontAwesomeIcon
                                      color="red"
                                      size="15px"
                                      icon={faWindowClose}
                                    />
                                  </button>
                                </li>
                              ))
                              : ""}
                          </ul>
                        </Col>
                        <Col md={8} className='p-2 mb-3'>
                          <div className='d-flex mb-2'>
                            <div className='mt-2 '>
                              <h6>Uploaded Files :</h6>
                            </div>
                            {/* <div className="ml-auto">
                              <Input
                                type="text"
                                className="form-control w-100"
                                placeholder="Search Assets"
                                value={searchFileInput}
                                onChange={handleSearchChange}
                              />
                            </div> */}
                          </div>
                          <ReactTable
                            data={filterAssets}
                            columns={columns}
                            className="-fixed -highlight -striped"
                            getTheadProps={() => {
                              return {
                                style: {
                                  background: "#B1FA63",
                                  overflowY: "hidden",
                                  color: "#243837",
                                },
                              };
                            }}
                            defaultPageSize={5}
                            noDataText={"No Data Found."}
                          />
                        </Col>
                      </Row>
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      7 ? (
                      <ColorPalate
                        questionInfo={filteredQuestions[currentQuestionIndex]}
                        setSubmitQuestionAnswerData={
                          setSubmitQuestionAnswerData
                        }
                      />
                    ) : filteredQuestions[currentQuestionIndex].questionType ===
                      8 ? (
                      <FormGroup>
                        <Input
                          id={`id${filteredQuestions[currentQuestionIndex].id}`}
                          name={`name${filteredQuestions[currentQuestionIndex].id}`}
                          type="text"
                          className="w-50 mb-3"
                          value={textboxValue ? textboxValue : ""}
                          onChange={(e) => {
                            let value = e.target.value;
                            if (filteredQuestions[currentQuestionIndex].questionType === 8) {
                              value = value.slice(0, 50);
                            }
                            setTextboxValue(value);
                            setSubmitQuestionAnswerData(
                              filteredQuestions[currentQuestionIndex],
                              0,
                              value,
                              "ShortText"
                            );
                          }}
                          required
                        />
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              )}
              <Col
                md={12}
                className="d-flex justify-content-center pb-3 prev-next"
              >
                {currentQuestionIndex === filteredQuestions.length - 1 ? (
                  <Button
                    variant="primary"
                    className="btn-background-color "
                    onClick={() => {
                      if(firstCategoryTab== 2){
                        handleSubmitAnswer();
                        setCurrentQuestionIndex(0)
                      }
                      else{
                        handleSubmitAnswer();
                        props.closeQuestionnaire();
                      }
                      
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    className="btn-background-color "
                    onClick={() => {
                      handleNextClick();
                      handleSubmitAnswer();
                    }}
                    disabled={filteredQuestions[currentQuestionIndex] ? (filteredQuestions[currentQuestionIndex].id == 1 || filteredQuestions[currentQuestionIndex].id == 69) ? textboxValue ? false : true : false : false}
                  >
                    Next
                  </Button>
                )}

              </Col>
            </Row>
          </Card>
          <Card
            className={`card-of-problemarea ${!isPreviewModeOn ? 'd-none' : ''}`}
            style={{ background: '#D0EFB1' }}>
            <Row className='m-3 bg-white inner-card-question'>
              {!isPreviouslyAnswered ?<Col md={12} className='p-4'>
                {
                  categoryTab.map(Tab => (
                    Tab.id == currentId ? <><h5 className="mb-3"><b>{Tab.tab} :</b></h5></> : ''
                  ))
                }
                {currentId ? filteredQuestions.map((que) => (
                  <>
                    {currentId === que.categoryTabsMapping &&
                      <>
                        <h6 className="mt-3"><b>{que.questionText}</b></h6>
                        {/* <p>{que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p> */}
                        {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors == '' ? setSubmittedColors(JSON.parse(que.answerObject[0].answerText.replace(/'/g, '"'))) : '' : '' : ''}
                        {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors != '' ?
                          <>
                            {/* primery */}
                            <div className="d-flex">
                              <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Primary colors : </p>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.primery[0] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.primery[1] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[1]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.primery[2] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.primery[2]}</span>
                              </div>
                            </div>
                            {/* secondery */}
                            <div className="d-flex">
                              <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Secondary colors : </p>
                              <div className="ml-2 d-flex">
                                <span style={{ backgroundColor: submittedColors.secondary[0] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.secondary[0]}</span>
                              </div>
                              <div className="ml-4 d-flex ">
                                <span style={{ backgroundColor: submittedColors.secondary[1] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.secondary[1]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.secondary[2] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.secondary[2]}</span>
                              </div>
                            </div>
                            {/* tertiary */}
                            <div className="d-flex">
                              <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Tertiary colors : </p>
                              <div className="ml-4 d-flex">&nbsp;
                                <span style={{ backgroundColor: submittedColors.tertiary[0] }} className='color-box ' />
                                <span className="mb-1"> {submittedColors.tertiary[0]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.tertiary[1] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.tertiary[1]}</span>
                              </div>
                              <div className="ml-4 d-flex">
                                <span style={{ backgroundColor: submittedColors.tertiary[2] }} className='color-box' />
                                <span className="mb-1"> {submittedColors.tertiary[2]}</span>
                              </div>
                            </div>
                          </>
                          : '' : que.answerObject[0] ?
                          que.answerObject[0].answerText.includes('Files') ?
                            que.fileList ?
                              que.fileList.fileList.length > 0 ?
                                que.fileList.fileList.map((fileUrl) => (
                                  <div className="d-flex mb-1">
                                    <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                    <a className=" mb-2" href="#" onClick={() => openFile(fileUrl.attachmentFile)}>
                                      {fileUrl.actualFileName}
                                    </a>
                                  </div>
                                ))
                                : []
                              : []
                            : que.answerObject[0].answerText.includes("['") ?
                              que.answerOptionsForQ && Array.isArray(que.answerOptionsForQ) ? (
                                que.answerOptionsForQ.map((mulAns, index) => (
                                  <div>
                                    {que.answerObject.map((ansOpt) => (
                                      ansOpt.answerText.includes(mulAns.answerOptionText) ? (
                                        <p className="mb-0 ans-background">
                                          <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                        {mulAns.answerOptionText}
                                        </p>

                                      ) : (
                                        <p className="ml-5  mb-0">{mulAns.answerOptionText}</p>
                                      )
                                    ))}
                                  </div>
                                ))
                              ) : (
                                <p>-</p>
                              )
                              : que.answerObject[0] && (que.answerObject[0].answerText.startsWith('http://') || que.answerObject[0].answerText.startsWith('https://')) ? (
                                <p>
                                  <img src={AnsArrow} className='mr-2 mb-1 ml-4 ' alt="not found" />
                                  <a className='mb-1' href={que.answerObject[0].answerText} target="_blank" rel="noopener noreferrer">{que.answerObject[0].answerText}</a>
                                </p>
                              ) :
                                <p className="ans-background">
                                  <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                  {que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p> : ' - ' : <><img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> - </>}
                      </>
                    }
                  </>
                )) : ''}
              </Col>:
              <Col md={12} className='p-4'>
              {
                categoryTab.map(Tab => (
                  Tab.isActive == false ? <><h5 className="mb-3"><b>{Tab.tab} :</b></h5>
              {currentId ? filteredQuestions.map((que) => (
                <>
                  {Tab.id === que.categoryTabsMapping &&
                    <>
                      {/* <h6 className="mt-3"><b>{que.questionText}</b></h6> */}
                      {/* <p>{que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p> */}
                      {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors == '' ? setSubmittedColors(JSON.parse(que.answerObject[0].answerText.replace(/'/g, '"'))) : '' : '' : ''}
                      {que.answerObject[0] ? que.answerObject[0].answerText.includes(`{'primery':`) ? submittedColors != '' ?
                        <>
                          {/* primery */}
                          <h6 className="mt-3"><b>{que.questionText}</b></h6>
                          <div className="d-flex">
                            <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Primary colors : </p>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.primery[0] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.primery[1] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[1]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.primery[2] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.primery[2]}</span>
                            </div>
                          </div>
                          {/* secondery */}
                          <div className="d-flex">
                            <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Secondary colors : </p>
                            <div className="ml-2 d-flex">
                              <span style={{ backgroundColor: submittedColors.secondary[0] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.secondary[0]}</span>
                            </div>
                            <div className="ml-4 d-flex ">
                              <span style={{ backgroundColor: submittedColors.secondary[1] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.secondary[1]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.secondary[2] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.secondary[2]}</span>
                            </div>
                          </div>
                          {/* tertiary */}
                          <div className="d-flex">
                            <p> <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" /> Tertiary colors : </p>
                            <div className="ml-4 d-flex">&nbsp;
                              <span style={{ backgroundColor: submittedColors.tertiary[0] }} className='color-box ' />
                              <span className="mb-1"> {submittedColors.tertiary[0]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.tertiary[1] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.tertiary[1]}</span>
                            </div>
                            <div className="ml-4 d-flex">
                              <span style={{ backgroundColor: submittedColors.tertiary[2] }} className='color-box' />
                              <span className="mb-1"> {submittedColors.tertiary[2]}</span>
                            </div>
                          </div>
                        </>
                        : '' : que.answerObject[0] ?
                        que.answerObject[0].answerText.includes('Files') ?
                          que.fileList ?
                            que.fileList.fileList.length > 0 ?
                              <>
                              <h6 className="mt-3"><b>{que.questionText}</b> </h6>
                                {que.fileList.fileList.map((fileUrl) => (
                                  <div className="d-flex mb-1">
                                  
                                    <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                    <a className=" mb-2" href="#" onClick={() => openFile(fileUrl.attachmentFile)}>
                                      {fileUrl.actualFileName}
                                    </a>
                                  </div>
                                ))}
                              </>
                              : []
                            : []
                          : que.answerObject[0].answerText.includes("['") ?
                            que.answerOptionsForQ && Array.isArray(que.answerOptionsForQ) ? (
                              <>
                              <h6 className="mt-3"><b>{que.questionText}</b> </h6>
                              {
                                que.answerOptionsForQ.map((mulAns, index) => (
                                  <div>
                                    
                                    {que.answerObject.map((ansOpt) => (
                                      ansOpt.answerText.includes(mulAns.answerOptionText) ? (
                                        <p className="mb-0 ans-background">
                                          <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                        {mulAns.answerOptionText}
                                        </p>
  
                                      ) : (
                                        <p className="ml-5  mb-0">{mulAns.answerOptionText}</p>
                                      )
                                    ))}
                                  </div>
                                ))
                              }</>
                            ) : (
                              <p></p>
                            )
                            : que.answerObject[0] && (que.answerObject[0].answerText.startsWith('http://') || que.answerObject[0].answerText.startsWith('https://')) ? (
                              <p>
                                <h6 className="mt-3"><b>{que.questionText}</b></h6>
                                <img src={AnsArrow} className='mr-2 mb-1 ml-4 ' alt="not found" />
                                <a className='mb-1' href={que.answerObject[0].answerText} target="_blank" rel="noopener noreferrer">{que.answerObject[0].answerText}</a>
                              </p>
                            ) :<><h6 className="mt-3"><b>{que.questionText}</b> </h6>
                            <p className="ans-background">
                                
                                <img src={AnsArrow} className='mr-2 mb-1 ml-4' alt="not found" />
                                {que.answerObject[0] ? que.answerObject[0].answerText : ' '}</p> </>
                              : ' ' : ''}
                    </>
                  }
                </>
              )) : ''}
              </> : ''
                ))
              }
            </Col> }
            </Row>
          </Card>
        </Col>
      </Row>
      <CustomModal
        header={"All File List"}
        isOpen={isPreviousFile}
        toggle={() => setIsPreviousFile(!isPreviousFile)}
        size={"md"}
        // className='curve-popup'
        className="uploadedfile-card1 curve-popup"
      >

        <ViewFileList questionInfo={filteredQuestions[currentQuestionIndex]} />
      </CustomModal>
      <CustomModal
        header={"View Image/File"}
        isOpen={showFile}
        toggle={() => setShowFile(!showFile)}
        size={"lg"}
        className='curve-popup '

      >
        <Label>
          <div>
            <iframe
              src={fileUrl ? fileUrl : ''}
              title="File Display"
              width="100%"
              height="500"
            ></iframe>
          </div>
        </Label>
      </CustomModal>

      <CustomModal
        header={" Switch to Preview Mode?"}
        isOpen={isConfirmBoxOpen}
        toggle={() => { setIsConfirmBoxOpen(!isConfirmBoxOpen); setIsPreviewModeOn(false); setIsChecked(false); setIsCheckBoxClick(false); }}
        size={"md"}
        className="uploadedfile-card1 curve-popup"
      >
        <Card className='curve-popup '>
          <Row className='m-1'>
            <Col md={12} className='ml-3 mt-4'>
              <p className="mb-0">This mode is for viewing only. You cannot make changes while in this mode. To edit your answers, simply switch off this toggle.</p>
            </Col>
            <Col md={12} className='ml-3 d-flex '>
              <input type='checkbox'
                checked={isCheckBoxClick}
                onClick={() => setIsCheckBoxClick(!isCheckBoxClick)}
              />
              <p className="ml-2 mt-3">Don't ask me again</p>
            </Col>
            <Col md={12} className='mb-3 text-md-right'>
              <Button className="btn-background-color-cancle" onClick={() => { setIsPreviewModeOn(false); setIsConfirmBoxOpen(false); setIsChecked(false); setIsCheckBoxClick(false); }}>Cancel</Button>
              &nbsp;
              <Button className='btn-background-color' onClick={() => { setIsPreviewModeOn(true); setIsConfirmBoxOpen(false) }}>Switch</Button>
            </Col>
          </Row>
        </Card>
      </CustomModal>
      <CustomModal
        header={"All Submitted Questions"}
        isOpen={submitAnswerPopUp}
        toggle={() => setSubmitAnswerPopUp(!submitAnswerPopUp)}
        size={"lg"}
        // className='curve-popup'
        className="uploadedfile-card1 curve-popup"
      >
        <Card className='curve-popup'>
          {
            categoryTab.map(Tab => (
              <>
                <div className="m-3">
                  <div className="d-flex">
                    <h5><b>{Tab.tab}</b></h5>
                    {subCategoryCount && subCategoryCount.map((count) => (
                      <span className="ml-1 mt-1">
                        {Tab.id === count.categoryTabsMapping_id &&
                          <span>
                            {count.attemptedQuestionCount} of {count.questionCount} Completed
                          </span>
                        }
                      </span>
                    ))}
                  </div>
                  {filteredQuestions.map((que) => (
                    <>
                      {Tab.id === que.categoryTabsMapping &&
                        <>
                          <h5>{que.questionText}</h5>
                          <p>{que.answerObject[0] ? que.answerObject[0].answerText : ' - '}</p>
                          {/* console.log(que.fileList.fileList[0].attachmentFile) */}
                          {que.answerObject[0] ? que.answerObject[0].answerText == 'Files' ? que.fileList.fileList.map((fileUrl) => (

                            <iframe
                              src={fileUrl ? fileUrl.attachmentFile : ''}
                              title="File Display"
                              width="100%"
                              height="650"
                              className="m-2"
                            ></iframe>
                          )) : ''
                            : ' - '}
                        </>
                      }
                    </>
                  ))}
                </div>
              </>
            ))
          }



        </Card>

      </CustomModal>

    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    storeOverview: state.accountOverview.store.storeOverview,
    UserPermission: state.accountOverview.store.StoreUserPermission,
  };
};

export default connect(mapStateToProps)(CreateBrandProfileMainPage);
